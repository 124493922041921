import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Button, Paragraph } from "grommet"

import Seo from "../components/seo"
import Layout from "../components/layout"
import ServiceBenefits from "../components/servicebenefits"
import texture from "../static/images/texture.svg"

export default function Services({ location }) {
  return (
    <Layout>
      <Seo title="Services" />

      <Box>
        <Box width="xlarge" alignSelf="center" pad={{ horizontal: "medium" }}>
          <Heading>Our services</Heading>

          <Box
            background={{
              color: "brand",
              dark: false,
              size: "cover",
              position: "center",
              repeat: "no-repeat",
              image: `url(${texture})`,
            }}
            pad={{ vertical: "large", horizontal: "medium" }}
            direction="row-responsive"
            round="xsmall"
          >
            <Box basis="1/3">
              <Heading
                level={3}
                margin={{ top: "small", bottom: "none" }}
                size="small"
              >
              Become a leader in the future economy
              </Heading>
              <Paragraph>
              Get hands on with the tools of the future in one day masterclasses

              </Paragraph>
              <Link to="/services/shape-mindsets">
                <Button primary color="white" size="medium" label="Shape mindsets" />
              </Link>
            </Box>
          </Box>
          <Box
            direction="row-responsive"
            gap="small"
            margin={{top:"small"}}
          >
            <Box
              background="#01352d"
              basis="1/2"
              pad={{ vertical: "large", horizontal: "medium" }}
              direction="row"
              round="xsmall"
            >
              <Box width="medium">
                <Heading
                  level={3}
                  margin={{ top: "small", bottom: "none" }}
                  size="small"
                >
                  Build a competitive advantage
                </Heading>
                <Paragraph>
                De-risk ideas and design cutting edge services in 60 days

                </Paragraph>
                <Link to="/services/design-services">
                  <Button
                    primary
                    color="white"
                    size="medium"
                    label="Design services"
                  />
                </Link>
              </Box>
            </Box>
            <Box
              background="#01352d"
              basis="1/2"
              pad={{ vertical: "large", horizontal: "medium" }}
              direction="row"
              round="xsmall"
            >
              <Box width="medium">
                <Heading
                  level={3}
                  margin={{ top: "small", bottom: "none" }}
                  size="small"
                >
                Get ready for the future of work
                </Heading>
                <Paragraph>
                Rapidly build new capabilities through our Academy programmes
                </Paragraph>
                <Link to="/services/empower-your-team">
                  <Button
                    primary
                    color="white"
                    size="medium"
                    label="Empower your team"
                  />
                </Link>
              </Box>
            </Box>
          </Box>
          <ServiceBenefits from={location.pathname}/>
        </Box>
      </Box>
    </Layout>
  )
}
